define("ember-engines/-private/route-ext", ["@ember/routing/route", "@ember/application"], function (_route, _application) {
  "use strict";

  /*
    Creates an aliased form of a method that properly resolves external routes.
  */
  function externalAlias(methodName) {
    return function _externalAliasMethod(routeName, ...args) {
      let externalRoute = (0, _application.getOwner)(this)._getExternalRoute(routeName);
      let router = this._router || this.router;
      return router[methodName](externalRoute, ...args);
    };
  }
  _route.default.reopen({
    transitionToExternal: externalAlias('transitionTo'),
    replaceWithExternal: externalAlias('replaceWith')
  });
});