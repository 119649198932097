define("ember-cli-mirage/utils/ember-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hasEmberData = void 0;
  _exports.isDsModel = isDsModel;
  /* global requirejs */

  function _hasEmberData() {
    let matchRegex1 = /^ember-data/i;
    let matchRegex2 = /^@ember-data/i;
    return !!Object.keys(requirejs.entries).find(e => !!e.match(matchRegex2) || !!e.match(matchRegex1));
  }

  /**
    @hide
  */
  const hasEmberData = _exports.hasEmberData = _hasEmberData();

  /**
    @hide
  */
  function isDsModel(m) {
    return m && typeof m.eachRelationship === 'function';
  }
});