define("ember-engines/components/link-to-component", ["exports", "@ember/routing/link-component", "@ember/application", "@ember/object", "@ember/utils", "@ember/debug", "@embroider/macros/runtime"], function (_exports, _linkComponent, _application, _object, _utils, _debug, _runtime) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // this file is only used when ember is older than 3.24
  // ember-engines/addon/-private/engine-ext.js

  let LinkTo;
  let LinkComponent;
  if ((0, _runtime.macroCondition)(false)) {
    let {
      LinkComponent: LegacyLinkComponent
    } = require("@ember/legacy-built-in-components");
    LinkComponent = LegacyLinkComponent;
  } else {
    LinkComponent = _linkComponent.default;
  }
  if ((0, _runtime.macroCondition)(true)) {
    (true && !(false) && (0, _debug.deprecate)(`Importing from 'ember-engines/components/link-to-component' is deprecated, please use '@ember/routing/link-component' directly`, false, {
      id: 'ember-engines.link-to-override',
      until: '0.9.0',
      for: 'ember-engines',
      since: {
        enabled: '0.8.10'
      }
    }));
    LinkTo = LinkComponent;
  } else {
    LinkTo = LinkComponent.extend({
      _route: (0, _object.computed)('route', '_mountPoint', '_currentRouteState', function () {
        let routeName = this._super(...arguments);
        let mountPoint = (0, _object.get)(this, '_mountPoint');
        if (mountPoint && routeName !== (0, _object.get)(this, '_currentRoute')) {
          return this._namespacePropertyValue(mountPoint, routeName);
        }
        return routeName;
      }),
      _mountPoint: (0, _object.computed)(function () {
        return (0, _application.getOwner)(this).mountPoint;
      }),
      didReceiveAttrs() {
        this._super(...arguments);
        let owner = (0, _application.getOwner)(this);
        (true && !(owner.mountPoint !== undefined) && (0, _debug.assert)(`You attempted to use {{link-to}} within a routeless engine, this is not supported. Use {{link-to-external}} to construct links within a routeless engine. See http://ember-engines.com/guide/linking-and-external-links for more info.`, owner.mountPoint !== undefined));
        if (owner.mountPoint) {
          // Prepend engine mount point to targetRouteName
          if ('targetRouteName' in this) {
            this._prefixProperty(owner.mountPoint, 'targetRouteName');
          }

          // Prepend engine mount point to current-when if set
          if ((0, _object.get)(this, 'current-when') !== null) {
            this._prefixProperty(owner.mountPoint, 'current-when');
          }
        }
      },
      _prefixProperty(prefix, prop) {
        let propValue = (0, _object.get)(this, prop);

        // Sometimes `targetRouteName` will be a class
        if ((0, _utils.typeOf)(propValue) !== 'string') {
          return;
        }
        let namespacedPropValue;
        if (prop === 'current-when') {
          // `current-when` is a space-separated list of routes
          namespacedPropValue = propValue.split(' ');
          namespacedPropValue = namespacedPropValue.map(propValue => this._namespacePropertyValue(prefix, propValue));
          namespacedPropValue = namespacedPropValue.join(' ');
        } else {
          namespacedPropValue = this._namespacePropertyValue(prefix, propValue);
        }
        (0, _object.set)(this, prop, namespacedPropValue);
      },
      _namespacePropertyValue(prefix, propValue) {
        if (propValue === 'application') {
          return prefix;
        } else {
          return prefix + '.' + propValue;
        }
      }
    });
  }
  var _default = _exports.default = LinkTo;
});