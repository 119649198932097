define("@ember-data/canary-features/index", ["exports", "@ember/polyfills", "@ember-data/canary-features/default-features"], function (_exports, _polyfills, _defaultFeatures) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.SAMPLE_FEATURE_FLAG = _exports.REQUEST_SERVICE = _exports.REMOVE_RECORD_ARRAY_MANAGER_LEGACY_COMPAT = _exports.RECORD_DATA_STATE = _exports.RECORD_DATA_ERRORS = _exports.RECORD_ARRAY_MANAGER_IDENTIFIERS = _exports.IDENTIFIERS = _exports.FULL_LINKS_ON_RELATIONSHIPS = _exports.FEATURES = _exports.CUSTOM_MODEL_CLASS = void 0;
  /* globals EmberDataENV */

  const ENV = typeof EmberDataENV !== 'undefined' && EmberDataENV !== null ? EmberDataENV : {};
  function featureValue(value) {
    if (ENV.ENABLE_OPTIONAL_FEATURES && value === null) {
      return true;
    }
    return value;
  }
  const FEATURES = _exports.FEATURES = (0, _polyfills.assign)({}, _defaultFeatures.default, ENV.FEATURES);
  const SAMPLE_FEATURE_FLAG = _exports.SAMPLE_FEATURE_FLAG = featureValue(FEATURES.SAMPLE_FEATURE_FLAG);
  const RECORD_DATA_ERRORS = _exports.RECORD_DATA_ERRORS = featureValue(FEATURES.RECORD_DATA_ERRORS);
  const RECORD_DATA_STATE = _exports.RECORD_DATA_STATE = featureValue(FEATURES.RECORD_DATA_STATE);
  const REQUEST_SERVICE = _exports.REQUEST_SERVICE = featureValue(FEATURES.REQUEST_SERVICE);
  const IDENTIFIERS = _exports.IDENTIFIERS = featureValue(FEATURES.IDENTIFIERS);
  const CUSTOM_MODEL_CLASS = _exports.CUSTOM_MODEL_CLASS = featureValue(FEATURES.CUSTOM_MODEL_CLASS);
  const FULL_LINKS_ON_RELATIONSHIPS = _exports.FULL_LINKS_ON_RELATIONSHIPS = featureValue(FEATURES.FULL_LINKS_ON_RELATIONSHIPS);
  const RECORD_ARRAY_MANAGER_IDENTIFIERS = _exports.RECORD_ARRAY_MANAGER_IDENTIFIERS = featureValue(FEATURES.RECORD_ARRAY_MANAGER_IDENTIFIERS);
  const REMOVE_RECORD_ARRAY_MANAGER_LEGACY_COMPAT = _exports.REMOVE_RECORD_ARRAY_MANAGER_LEGACY_COMPAT = featureValue(FEATURES.REMOVE_RECORD_ARRAY_MANAGER_LEGACY_COMPAT);
});