define("ember-engines/-private/controller-ext", ["@ember/controller", "@ember/application"], function (_controller, _application) {
  "use strict";

  _controller.default.reopen({
    /*
      Creates an aliased form of a method that properly resolves external routes.
    */
    transitionToExternalRoute(routeName, ...args) {
      let externalRoute = (0, _application.getOwner)(this)._getExternalRoute(routeName);
      let target = this.target;
      let method = target.transitionToRoute || target.transitionTo;
      return method.apply(target, [externalRoute, ...args]);
    }
  });
});