define("ember-table/components/ember-table/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    data-test-ember-table-overflow
    class="ember-table-overflow"
    id="{{this.elementId}}-overflow"
  >
    <table>
      {{yield (hash
        api=this.api
        head=(component "ember-thead" api=this.api)
        body=(component "ember-tbody" api=this.api)
        foot=(component "ember-tfoot" api=this.api)
        loadingMore=(component "ember-table-loading-more" api=this.api)
      )}}
    </table>
  </div>
  <EmberTablePrivate::ScrollIndicators @api={{this.api}} />
  
  */
  {
    "id": "xXnR/r4h",
    "block": "[[[10,0],[14,\"data-test-ember-table-overflow\",\"\"],[14,0,\"ember-table-overflow\"],[15,1,[29,[[30,0,[\"elementId\"]],\"-overflow\"]]],[12],[1,\"\\n  \"],[10,\"table\"],[12],[1,\"\\n    \"],[18,1,[[28,[37,1],null,[[\"api\",\"head\",\"body\",\"foot\",\"loadingMore\"],[[30,0,[\"api\"]],[50,\"ember-thead\",0,null,[[\"api\"],[[30,0,[\"api\"]]]]],[50,\"ember-tbody\",0,null,[[\"api\"],[[30,0,[\"api\"]]]]],[50,\"ember-tfoot\",0,null,[[\"api\"],[[30,0,[\"api\"]]]]],[50,\"ember-table-loading-more\",0,null,[[\"api\"],[[30,0,[\"api\"]]]]]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[8,[39,3],null,[[\"@api\"],[[30,0,[\"api\"]]]],null],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"component\",\"ember-table-private/scroll-indicators\"]]",
    "moduleName": "ember-table/components/ember-table/template.hbs",
    "isStrictMode": false
  });
});