define("ember-cli-mirage/index", ["exports", "miragejs", "ember-cli-mirage/ember-data", "ember-cli-mirage/serializers/ember-data-serializer"], function (_exports, _miragejs, _emberData, _emberDataSerializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  var _exportNames = {
    discoverEmberDataModels: true,
    applyEmberDataSerializers: true,
    EmberDataSerializer: true
  };
  Object.defineProperty(_exports, "EmberDataSerializer", {
    enumerable: true,
    get: function () {
      return _emberDataSerializer.default;
    }
  });
  Object.defineProperty(_exports, "applyEmberDataSerializers", {
    enumerable: true,
    get: function () {
      return _emberData.applyEmberDataSerializers;
    }
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _miragejs.default;
    }
  });
  Object.defineProperty(_exports, "discoverEmberDataModels", {
    enumerable: true,
    get: function () {
      return _emberData.discoverEmberDataModels;
    }
  });
  Object.keys(_miragejs).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
    if (key in _exports && _exports[key] === _miragejs[key]) return;
    Object.defineProperty(_exports, key, {
      enumerable: true,
      get: function () {
        return _miragejs[key];
      }
    });
  });
});